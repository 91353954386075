<template>
    <div>
        <multiselect v-model="inputVal.value"
                     :searchable="true"
                     :options="Object.keys(groupsList)"
                     :multiple="inputVal.condition=='in'"
                     :custom-label="opt =>groupsList[opt]"
        ></multiselect>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
        ...mapGetters('CommonData', ['getGroups']),
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        groupsList() {
            let list = {}
            this.getGroups().forEach(item => {
                list[item.id] = item.name
            })

            return list
        }
    }
}
</script>